import React, { FunctionComponent } from 'react'
import { navigate } from 'gatsby'
import classNames from 'classnames'
import { Button, Card } from '@goorm-dev/components'

import Icon from '_images/Common/assets/common-not-found.svg'

import * as styles from './Error404.module.scss'

const Error404: FunctionComponent = function () {
  return (
    <section
      className={classNames(
        'd-flex flex-column align-items-center justify-content-center w-100 text-center',
        styles.Error404,
      )}
    >
      <Card
        className={classNames(
          'd-flex flex-column align-items-center justify-content-center',
          styles.Error404__wrapper,
        )}
      >
        <Icon className="mb-4" />
        <h5 className="mb-4">페이지를 찾을 수 없습니다.</h5>
        <p className="subtitle-1 text-gray-600 mb-4">
          페이지의 주소가 잘못되었거나 변경되어
          <br />
          요청한 페이지를 찾을 수 없습니다.
          <br />
          입력하신 주소를 다시 한 번 확인해주시고
          <br />
          문제가 반복되는 경우 아래 메일로 문의 부탁드립니다.
        </p>
        <a href="mailto: contact@goorm.io" className="text-blue-700 mb-4">
          contact@goorm.io
        </a>
        <Button size="lg" color="primary" outline onClick={() => navigate(-1)}>
          이전 페이지로
        </Button>
      </Card>
    </section>
  )
}

export default Error404
